import { defineStore } from "pinia";
import lodash from "lodash";
import useRefreshToken from "~/composables/useRefreshToken";
import { DESCRIPTION_LIMIT, NAME_LIMIT } from "~/data";
import {
  getAudienceActiveCount,
  getAudienceData,
  getAudienceFilters,
  getBrands,
  getRegions,
} from "~/composables/useFetchData";
import {
  sanitizeInput,
  toggleFilterValue,
  updateBehavioralFilter,
  mapAndSortDropdownValues,
  revalidateSelections,
  revalidateBehavioralSelections,
  setDemographicFilters,
  setBehavioralFilters,
} from "~/utils";
const { refreshToken } = useRefreshToken();

export const demographicOptions: DemographicOptionsResponseType[] = [
  "gender",
  "age_distribution",
  "city_tier",
  "device_type",
  "arrival_destination",
  "departure_destination",
];
export const audienceCreationInitialState: AudienceCreationStateType = {
  id: null,
  name: "",
  description: "",
  selectedBrandId: null,
  selectedRegionId: null,
  source: null,
  segmentView: "table",
  activeUuids: 0,
  // Segment
  selectedSegmentId: null,
  // Segments table
  selectedRegionPage: null,
  selectedSearchQuery: "",
  selectedSortedColumn: "",
  selectedSortOrder: "none",
  // Demographics
  gender: [],
  age_distribution: [],
  city_tier: [],
  device_type: [],
  arrival_destination: [],
  departure_destination: [],
  // Behavioral
  behavioralSelections: {},
};
export const audienceStoreInitialState: AudienceStoreType = {
  step: 1,
  loading: true,
  brands: [],
  regions: [],
  filtersToShow: { demographics: [] },
  showedOptions: [],
  showFilters: false,
  // Demographics
  demographicOptions: {},
  // Behavioral
  behavioralOptions: {},
  audienceCreation: lodash.cloneDeep(audienceCreationInitialState),
};

export const createIncludeExcludeFilter = (filterIds: {
  include: string[];
  exclude: string[];
}): { include: string[] | undefined; exclude: string[] | undefined } | undefined => {
  return filterIds.include.length || filterIds.exclude.length
    ? {
        include: filterIds.include.length > 0 ? filterIds.include : undefined,
        exclude: filterIds.exclude.length > 0 ? filterIds.exclude : undefined,
      }
    : undefined;
};
export const createIncludeFilter = (selectedIds: string[]): { include: string[] } | undefined => {
  return selectedIds.length > 0 ? { include: selectedIds } : undefined;
};

export const useAudienceStore = defineStore("audienceStore", {
  state: (): AudienceStoreType => audienceStoreInitialState,
  getters: {
    validStepOne(state) {
      return (
        state.audienceCreation.name !== "" &&
        state.audienceCreation.description !== "" &&
        state.audienceCreation.selectedRegionId !== null &&
        state.audienceCreation.selectedBrandId !== null
      );
    },
    validStepTwo(state) {
      if (state.audienceCreation.source === "segment") {
        return state.audienceCreation.selectedSegmentId !== null && state.audienceCreation.activeUuids > 0;
      } else if (state.audienceCreation.source === "pixel") {
        return state.audienceCreation.activeUuids > 0;
      }
      return false;
    },
    disableNextButton() {
      if (this.step === 1) {
        return !this.validStepOne;
      } else if (this.step === 2) {
        return !this.validStepTwo;
      } else if (this.step === 3) {
        return false;
      }
      return true;
    },
    filters(state) {
      const demographicFilters = {
        gender: createIncludeFilter(state.audienceCreation.gender),
        age_distribution: createIncludeFilter(state.audienceCreation.age_distribution),
        city_tier: createIncludeFilter(state.audienceCreation.city_tier),
        device_type: createIncludeFilter(state.audienceCreation.device_type),
        arrival_destination: createIncludeFilter(state.audienceCreation.arrival_destination),
        departure_destination: createIncludeFilter(state.audienceCreation.departure_destination),
      };

      const behavioralFilters = Object.keys(state.audienceCreation.behavioralSelections).reduce(
        (acc, key) => {
          acc[key] = createIncludeExcludeFilter(state.audienceCreation.behavioralSelections[key]);
          return acc;
        },
        {} as Record<string, any>,
      );

      return { ...demographicFilters, ...behavioralFilters };
    },
  },
  actions: {
    setStep(step: number) {
      this.step = step;
    },
    setStepBack() {
      this.step--;
    },
    setStepNext() {
      this.step++;
      const currentStep = this.step;
      if (currentStep === 4) {
        this.resetAudienceCreation();
      }
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setSelectedBrandId(value: string) {
      this.audienceCreation.selectedBrandId = value;
    },
    setSelectedRegionId(value: number) {
      this.audienceCreation.selectedRegionId = value;
    },
    async setSource(value: "pixel" | "segment" | null) {
      this.audienceCreation.source = value;
      if (value === "pixel" && this.audienceCreation.selectedSegmentId !== null) {
        this.audienceCreation.selectedSegmentId = null;
        this.clearSelectedFilters();
        await this.getUuidsAndFilters();
      } else if (value === "segment") {
        this.audienceCreation.segmentView = "table";
      }
      if (this.audienceCreation.selectedSegmentId !== null) {
        this.clearSelectedFilters();
        await this.getUuidsAndFilters();
      }
    },
    setSourceView(value: "table" | "filters") {
      this.audienceCreation.segmentView = value;
    },
    // Segments table
    setSelectedSegmentPage(value: number | null) {
      this.audienceCreation.selectedRegionPage = value;
    },
    setSelectedSearchQuery(value: string) {
      this.audienceCreation.selectedSearchQuery = value;
    },
    setSelectedSortedColumn(value: string) {
      this.audienceCreation.selectedSortedColumn = value;
    },
    setSelectedSortOrder(value: SortOrdersType) {
      this.audienceCreation.selectedSortOrder = value;
    },
    clearSelectedFilters() {
      this.audienceCreation.activeUuids = 0;
      this.audienceCreation.gender = [];
      this.audienceCreation.age_distribution = [];
      this.audienceCreation.city_tier = [];
      this.audienceCreation.device_type = [];
      this.audienceCreation.arrival_destination = [];
      this.audienceCreation.departure_destination = [];
      Object.keys(this.audienceCreation.behavioralSelections).forEach((key) => {
        this.audienceCreation.behavioralSelections[key] = { include: [], exclude: [] };
      });
      this.showedOptions = [];
      this.showFilters = false;
    },
    async setSelectedSegmentId(value: string | null) {
      this.audienceCreation.selectedSegmentId = value;
      this.clearSelectedFilters();
      this.audienceCreation.segmentView = "filters";
      await this.getUuidsAndFilters();
    },
    clearSelectedSegmentId() {
      this.audienceCreation.selectedSegmentId = null;
    },
    handleNameInput(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = sanitizeInput(target.value, NAME_LIMIT);
      target.value = value;
      this.audienceCreation.name = value;
    },
    handleDescriptionInput(event: Event) {
      const target = event.target as HTMLTextAreaElement;
      let value = target.value;
      if (value.length > DESCRIPTION_LIMIT) {
        value = value.substring(0, DESCRIPTION_LIMIT);
      }
      target.value = value;
      this.audienceCreation.description = value;
    },
    resetAudienceCreation() {
      this.audienceCreation = lodash.cloneDeep(audienceCreationInitialState);
    },
    setShowFilters(value: boolean) {
      this.showFilters = value;
    },
    // Demographics
    async setSelectedDemographicFilter(filterKey: DemographicOptionsResponseType, value: string) {
      this.audienceCreation[filterKey] = toggleFilterValue(this.audienceCreation[filterKey] as string[], value);
      await this.getUuidsAndFilters(); // Fetch updated filters after selection
    },
    async setSelectedDemographicFilters(filterKey: DemographicOptionsResponseType, values: string[]) {
      this.audienceCreation[filterKey] = values;
      await this.getUuidsAndFilters();
    },
    async clearDemographicFilters(filterKey: DemographicOptionsResponseType) {
      this.audienceCreation[filterKey] = [];
      await this.getUuidsAndFilters();
    },
    // Behavioral
    async setSelectedIncludeBehavioralFilter(key: string, values: string[]) {
      updateBehavioralFilter(this.audienceCreation.behavioralSelections, key, values, "include");
      await this.getUuidsAndFilters();
    },
    async setSelectedExcludeBehavioralFilter(key: string, values: string[]) {
      updateBehavioralFilter(this.audienceCreation.behavioralSelections, key, values, "exclude");
      await this.getUuidsAndFilters();
    },
    async clearBehavioralFilters(key: string) {
      this.audienceCreation.behavioralSelections[key] = { include: [], exclude: [] };
      await this.getUuidsAndFilters();
    },
    // API Calls
    async getOptions() {
      if (this.brands.length === 0 || this.regions.length === 0) {
        const [brands, regions] = await Promise.all([await getBrands(), await getRegions()]);
        if (brands.error.value?.statusCode === 401 || regions.error.value?.statusCode === 401) {
          await refreshToken();
        } else if (brands.data.value && regions.data.value) {
          this.brands = brands.data.value.results
            ? Object.entries(brands.data.value.results)
                .map(([id, name]) => ({ id, name }))
                .sort((a, b) => a.name.localeCompare(b.name))
            : [];
          this.regions = mapAndSortDropdownValues(
            regions.data.value as unknown as Record<string, string>[],
            "id",
          ).filter((region) => region.name !== "WW");
        }
      }
    },
    async getUuidsAndFilters() {
      this.loading = true;
      // Preserve current selections before fetching new filters
      const preservedBehavioralSelections = lodash.cloneDeep(this.audienceCreation.behavioralSelections);
      const preservedDemographicSelections = {
        gender: [...this.audienceCreation.gender],
        age_distribution: [...this.audienceCreation.age_distribution],
        city_tier: [...this.audienceCreation.city_tier],
        device_type: [...this.audienceCreation.device_type],
        arrival_destination: [...this.audienceCreation.arrival_destination],
        departure_destination: [...this.audienceCreation.departure_destination],
      };

      const segmentSplit = this.audienceCreation.selectedSegmentId?.split("_") ?? [];
      const requestConfig = {
        segment_id: this.audienceCreation.selectedSegmentId ? segmentSplit[segmentSplit.length - 1] : null,
        filters: this.filters,
      };

      const [filtersData, activeUuidsData] = await Promise.all([
        await getAudienceFilters(requestConfig),
        await getAudienceActiveCount(requestConfig),
      ]);
      if (filtersData.error.value?.statusCode === 401 || activeUuidsData.error.value?.statusCode === 401) {
        await refreshToken();
      } else if (filtersData.data.value && activeUuidsData.data.value) {
        // Update active UUIDs count
        this.audienceCreation.activeUuids = activeUuidsData.data.value.results.active_uuids;
        // Update behavioral options and ensure structure is defined
        this.behavioralOptions = {};
        Object.entries(filtersData.data.value.results.behavioral).forEach(([key, value]) => {
          this.behavioralOptions[key] = {
            name: value.display_name,
            values: mapAndSortDropdownValues(value.values),
          };
          // Ensure the object is created in audienceCreation.behavioralSelections
          if (!this.audienceCreation.behavioralSelections[key]) {
            this.audienceCreation.behavioralSelections[key] = { include: [], exclude: [] };
          }
        });
        // Revalidate and reapply preserved behavioral selections only if they still exist in the current options
        Object.keys(preservedBehavioralSelections).forEach((key) => {
          const currentBehavioralOptions = this.behavioralOptions[key]?.values.map((v) => v.id);
          if (currentBehavioralOptions && currentBehavioralOptions.length > 0) {
            this.audienceCreation.behavioralSelections[key] = revalidateBehavioralSelections(
              preservedBehavioralSelections[key],
              currentBehavioralOptions,
            );
          }
        });

        // Update demographic options, and set them to undefined if not present
        demographicOptions.forEach((key) => {
          this.demographicOptions[key] = filtersData.data.value?.results.demographics[key]
            ? mapAndSortDropdownValues(filtersData.data.value?.results.demographics[key].values)
            : undefined;
        });
        // Revalidate and reapply preserved demographic selections
        demographicOptions.forEach((key) => {
          const currentDemographicOptions = this.demographicOptions[key]?.map((option) => option.id) || [];
          this.audienceCreation[key] = revalidateSelections(
            preservedDemographicSelections[key],
            currentDemographicOptions,
          );
        });
      }
      this.loading = false;
    },
    async getExistingData(id: string, isDuplicate = false) {
      const { data, error } = await getAudienceData(id);
      if (error.value?.statusCode === 401) {
        await refreshToken();
      } else if (data.value) {
        await this.setAudienceState(data.value.results);
        if (!isDuplicate) {
          this.setStep(3);
        } else {
          this.setStep(1);
        }
        await this.getUuidsAndFilters();
      }
    },
    async setAudienceState(inboundData: AudienceResponseType) {
      await this.getOptions();

      this.audienceCreation.id = inboundData.audience_id;
      this.audienceCreation.selectedBrandId = inboundData.camp_brand.brand_id;
      this.audienceCreation.selectedRegionId = inboundData.region.id;
      this.audienceCreation.name = inboundData.name;
      this.audienceCreation.description = inboundData.description;
      this.audienceCreation.source = inboundData.source;
      // Segment
      this.audienceCreation.selectedSegmentId =
        inboundData.source === "segment" ? `${inboundData.segment_name}_${inboundData.segment_id}` : null;
      this.audienceCreation.selectedRegionPage = inboundData.source === "segment" ? inboundData.segment_page : null;
      this.audienceCreation.selectedSearchQuery =
        inboundData.source === "segment" ? inboundData.segment_search_query : null;
      this.audienceCreation.selectedSortedColumn =
        inboundData.source === "segment" ? inboundData.segment_sorted_column : null;
      this.audienceCreation.selectedSortOrder =
        inboundData.source === "segment" ? inboundData.segment_sort_order : null;

      // List of known demographic keys
      const demographicKeys: DemographicOptionsResponseType[] = [
        "gender",
        "age_distribution",
        "city_tier",
        "device_type",
        "arrival_destination",
        "departure_destination",
      ];
      // Handle filters as a flat structure
      const filters = inboundData.filters;
      // Set demographic filters
      this.audienceCreation = setDemographicFilters(this.audienceCreation, filters, demographicKeys);
      // Set behavioral filters (all other filters are considered behavioral)
      this.audienceCreation.behavioralSelections = setBehavioralFilters(
        this.audienceCreation.behavioralSelections,
        filters,
        demographicKeys,
      );
    },
  },
});
