/**
 * Adds or removes a value from a filter list.
 *
 * @param {string[]} currentValues - The current array of selected filter values.
 * @param {string} value - The value to add or remove from the filter list.
 * @returns {string[]} - The updated array with the value either added or removed.
 */
export function toggleFilterValue(currentValues: string[], value: string): string[] {
  return currentValues.includes(value) ? currentValues.filter((v) => v !== value) : [...currentValues, value];
}

/**
 * Updates the include or exclude values for a specific behavioral filter key.
 * Ensures that the key is initialized before updating the values.
 *
 * @param {Record<string, { include: string[]; exclude: string[] }>} behavioralSelections - The current behavioral selections object.
 * @param {string} key - The key of the behavioral filter to update.
 * @param {string[]} values - The new values to set for include or exclude.
 * @param {"include" | "exclude"} type - The type of update (either "include" or "exclude").
 */
export function updateBehavioralFilter(
  behavioralSelections: Record<string, { include: string[]; exclude: string[] }>,
  key: string,
  values: string[],
  type: "include" | "exclude",
): void {
  if (!behavioralSelections[key]) {
    behavioralSelections[key] = { include: [], exclude: [] };
  }
  behavioralSelections[key][type] = values;
}

/**
 * Sets filter values for a given filter key.
 *
 * @param {string[]} values - The array of values to set for the filter.
 * @returns {string[] | undefined} - The updated filter values or undefined if no values are present.
 */
export function setFilterValues(values: string[]): string[] | undefined {
  return values.length > 0 ? values : undefined;
}

/**
 * Revalidates selected values by ensuring that they exist within the available options.
 *
 * @param {string[]} selectedValues - The list of currently selected values.
 * @param {string[]} availableOptions - The list of available options to compare against.
 * @returns {string[]} - A filtered list of selected values that exist in the available options.
 */
export const revalidateSelections = (selectedValues: string[], availableOptions: (string | number)[]): string[] => {
  return selectedValues.filter((value) => availableOptions.includes(value));
};

/**
 * Revalidates the behavioral selections (both 'include' and 'exclude' lists) by ensuring that they exist
 * within the available options.
 *
 * @param {{   include: string[];   exclude: string[] }} preservedSelections - The original include/exclude selections to revalidate.
 * @param {(string | number)[]} availableOptions - The list of available options to compare against.
 * @returns {{   include: string[];   exclude: string[] }} - A revalidated object containing filtered include and exclude lists.
 */
export const revalidateBehavioralSelections = (
  preservedSelections: { include: string[]; exclude: string[] },
  availableOptions: (string | number)[],
): { include: string[]; exclude: string[] } => {
  return {
    include: revalidateSelections(preservedSelections.include, availableOptions),
    exclude: revalidateSelections(preservedSelections.exclude, availableOptions),
  };
};

/**
 * Sets demographic filters to the audience creation state.
 *
 * This function iterates through the demographic keys and applies the corresponding filters
 * from the filters object to the audience creation state. If a demographic filter exists in the
 * filters object, it updates the audienceCreation object with the included values.
 *
 * @param {AudienceCreationStateType} audienceCreation - The current state of audience creation.
 * @param {DemographicFilters & BehavioralFilters} filters - The filters object containing demographic and behavioral filter values.
 * @param {DemographicOptionsResponseType[]} demographicKeys - A list of demographic keys that should be processed.
 * @returns {AudienceCreationStateType} - The updated audience creation state with applied demographic filters.
 */
export const setDemographicFilters = (
  audienceCreation: AudienceCreationStateType,
  filters: DemographicFilters & BehavioralFilters,
  demographicKeys: DemographicOptionsResponseType[],
): AudienceCreationStateType => {
  demographicKeys.forEach((key) => {
    if (filters[key]) {
      audienceCreation[key] = filters[key]?.include || [];
    }
  });
  return audienceCreation;
};

/**
 * Sets behavioral filters to the audience creation's behavioralSelections object.
 *
 * This function iterates through the filters object and applies any filter that is not a demographic filter
 * as a behavioral filter. It checks if a behavioral filter already exists, and if not, it initializes
 * it in the behavioralSelections object. It then updates the include and exclude values for the behavioral filter.
 *
 * @param {Record<string, { include: string[]; exclude: string[] }>} behavioralSelections - The current behavioral selections object.
 * @param {DemographicFilters & BehavioralFilters} filters - The filters object containing both demographic and behavioral filter values.
 * @param {DemographicOptionsResponseType[]} demographicKeys - A list of demographic keys to exclude from the behavioral filters.
 * @returns {Record<string, { include: string[]; exclude: string[] }>} - The updated behavioral selections object with applied filters.
 */
export const setBehavioralFilters = (
  behavioralSelections: Record<string, { include: string[]; exclude: string[] }>,
  filters: DemographicFilters & BehavioralFilters,
  demographicKeys: DemographicOptionsResponseType[],
): Record<string, { include: string[]; exclude: string[] }> => {
  Object.keys(filters).forEach((key) => {
    if (!demographicKeys.includes(key as DemographicOptionsResponseType)) {
      // Initialize the behavioral filter if it doesn't exist
      if (!behavioralSelections[key]) {
        behavioralSelections[key] = { include: [], exclude: [] };
      }
      behavioralSelections[key].include = filters[key]?.include || [];
      behavioralSelections[key].exclude = filters[key]?.exclude || [];
    }
  });
  return behavioralSelections;
};
